import React, { useState, useEffect } from "react";
import "./Header.scss";
import { ImagePass } from "../../Image/ImagePass";
import { decodeEntities } from "../../../utils/helpers";
import AOS from "aos";
import "aos/dist/aos.css";

const renderHeaderVideo = (headerVideoUrl) => {
  const videoExtensions = ["mp4", "mov"]; // add more file extensions here
  const isHeaderUrlAFile = videoExtensions.find((ext) =>
    String(headerVideoUrl).toLowerCase().includes(ext)
  );

  if (isHeaderUrlAFile) {
    return (
      <video
        src={headerVideoUrl}
        title="Header Video"
        className="header--video"
        muted
        autoPlay
        loop
        playsInline
      ></video>
    );
  }

  let iframeUrl = headerVideoUrl;

  if (headerVideoUrl.includes("youtube") && !headerVideoUrl.includes("?")) {
    const videoId = headerVideoUrl.split("/").pop();
    iframeUrl = `${iframeUrl}?autoplay=1&loop=1&playlist=${videoId}&controls=0&rel=0&mute=1`;
  }

  return (
    <iframe
      src={iframeUrl}
      title="Header Video"
      frameBorder="0"
      autoPlay
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="header--video"
      muted
    ></iframe>
  );
};

export const Header = ({ compact, header, image, headerVideo }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);

    AOS.init();
  }, []);

  return (
    <section className={`header ${compact ? "header--compact" : ""}`}>
      {!headerVideo ? (
        <ImagePass src={image} />
      ) : (
        renderHeaderVideo(headerVideo)
      )}
      <div className="wrap">
        {!compact && (
          <div
            className="header--shapes--small"
            data-aos="fade-right"
            data-aos-duration="1000"
          />
        )}
        <div className={`content ${isLoaded ? "animate" : ""}`}>
          <h1>{header}</h1>
        </div>
        {!compact && (
          <div
            className="header--shapes--large"
            data-aos="fade-right"
            data-aos-duration="1000"
          />
        )}
      </div>
    </section>
  );
};
