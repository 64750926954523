import React from "react";
import GatsbyLink from "../../GatsbyLink";
import RenderContent from "../../RenderContent";
import { ImagePass } from "../../Image/ImagePass";
import "./PostListItem.scss";
import {
  decodeEntities,
  getCategory,
  sectorCategoories,
} from "../../../utils/helpers";

export const PostListItem = ({
  data: {
    excerpt,
    title,
    path,
    date,
    project_category,
    categories: post_category,
    featured_media,
    acf,
  },
  categories,
  projectCategories,
  showMedia = false,
}) => {
  const projectSectorCategory = project_category?.find((category) =>
    sectorCategoories.includes(category)
  );

  const { layout } = acf;

  const postExcerpt =
    layout.find((field) => field.__typename.includes("postInformation"))
      ?.postDescription ||
    excerpt ||
    "";
  return (
    <div className="post-item">
      {showMedia && (
        <GatsbyLink to={path}>
          <div className="post-item-image-container">
            <ImagePass src={featured_media} />
          </div>
        </GatsbyLink>
      )}
      <span className="eyebrow">
        {post_category
          ? post_category[0].name
          : projectSectorCategory
          ? getCategory(projectSectorCategory, categories.edges)
          : "Uncategorised"}
      </span>
      <h3 className="post-item-heading">
        <GatsbyLink to={path}>{decodeEntities(title)}</GatsbyLink>
      </h3>
      {<RenderContent content={postExcerpt} />}
      <GatsbyLink to={path} className="button--dark">
        Read more
      </GatsbyLink>
    </div>
  );
};
